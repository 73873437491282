<template>
  <section class="Uniteverse wrapper" id="article">
    <h1>
      <span>1</span>
      {{ $t("home.uniteverse.title") }}
    </h1>
    <article>
      <p>
        {{ $t("home.uniteverse.description0") }}
      </p>
      <p>
        {{ $t("home.uniteverse.description1") }}
      <p>
        {{ $t("home.uniteverse.description2") }}
      </p>
      <p>
        {{ $t("home.uniteverse.description3") }}
      </p>
    </article>
  </section>
</template>

<script>
export default {
  name: "Uniteverse",
  components: {},
  props: [],
  data() {
    return {}
  },
  computed: {},
  methods: {},
  mounted() {
  },
}
</script>

<style lang="scss">
/*  Uniteverse  */
.Uniteverse {
  width: 100%;
  height: auto;
}
</style>